import React from "react";
import { Button, Input, styled } from "@100mslive/roomkit-react";
import { useParams } from "react-router-dom";

//  useparams
// import { isStreamingKit } from "../../common/utils";

const PreviewName = ({ name, onChange, onJoin, enableJoin }) => {
  const { roomId, role } = useParams();
  console.log(roomId, role);
  const formSubmit = e => {
    e.preventDefault();
  };
  return (
    <Form onSubmit={formSubmit}>
      {/* <Input
        required
        id="name"
        css={{ w: "100%" }}
        value={name}
        onChange={e => onChange(e.target.value.trimStart())}
        placeholder="Enter your name"
        autoFocus
        autoComplete="name"
      /> */}

      <FullWidthButton type="submit" onClick={onJoin}>
        Join the meeting
      </FullWidthButton>
    </Form>
  );
};

const Form = styled("form", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "$4",
  mt: "$10",
  mb: "$10",
});
const FullWidthButton = styled(Button, {
  width: "100%",
  backgroundColor: "#5B855D !important",
  borderColor: "#5B855D !important",
});

export default PreviewName;
